import React from 'react'
import { graphql } from 'gatsby'
import Theme, {
  Container,
  Headline,
  Layout,
  SectionRecentPosts,
  SEO,
} from '../components'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import page from '../data/pages/blog.yml'

type BlogProps = {
  data: {
    allWpPost: {
      nodes: Array<{
        slug: string
        title: string
        excerpt: string
      }>
    }
  }
}

const BlogPage = ({ data }: BlogProps): React.ReactElement => {
  const hasPosts = data.allWpPost.nodes

  return (
    <Theme>
      <SEO title={page?.title} />

      <Layout>
        <Container>
          <Headline tagName="h1" className="mt-12 mb-12">
            Blog
          </Headline>
          {/* <p className="text-2xl mb-5">Read the blog.</p> */}

          {hasPosts ? (
            <SectionRecentPosts numberOfPosts={10} showMore={false} />
          ) : (
            <p>Noch keine Blog-Beiträge.</p>
          )}

          <SectionNewsletter page="Blog Übersicht" />
        </Container>
      </Layout>
    </Theme>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`

export default BlogPage
